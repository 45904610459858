.operator-block {
  position: relative;
  &::after {
    border-right: 1px solid rgba(240, 240, 240, 1);
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    bottom: 0;
  }
}

.filter-item {
  padding: 12px 12px;
  border: 1px solid rgba(240, 240, 240, 1);
  display: flex;
}

.filter-subitem {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin: auto 5px auto 20px;
  margin-left: 20px;
  & > :global(.ant-form-item) {
    margin-bottom: 0;
  }
}

.h-100 {
  height: 100%;
}

.btn-del {
  border: none !important;
}
.filter-popover {
  display: flex;
  margin-top: 5px;
}
