@import '../../../../../styles/colors.scss';

.table {
  table {
    border: 1px solid $border-color;
  }
  td:global(.ant-table-cell) {
    padding: 0 0 !important;
    width: 200px !important;
    text-align: center;
    border: 0 !important;
  }
}

.columnsNameItem {
  display: flex;
  min-width: 150px;
  width: 100%;
  height: 100%;
  min-height: 64px;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.contentBlockItem {
  display: flex;
  min-width: 250px;
  width: 100%;
  height: 100%;
  min-height: 64px;
  text-align: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  flex-direction: column;
  padding: 10px 20px;
  &.active {
    background: #fafbff;
    border: 1px solid #336cfb;
  }
}

.goldRecordItem {
  background: #fafbff;
  border: 1px solid #336cfb;
}
