.container {
  border-radius: 16px;
  background: #fff;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .VersionCards {
    display: flex;
    gap: 24px;
  }
}
