.form_list {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > :global(.ant-form-item-required) & > span {
    display: none !important;
  }

  & > :global(.ant-form-item-required) *:after {
    display: none !important;
  }
}

.select_form_item {
  flex-grow: 1;
}

.input_form_item {
  width: 130px;
}

.block {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.title {
  display: flex;
  align-items: center;
  padding: 0;
  height: 30px;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select {
  width: 100% !important;
}
