.ant-form-item-label > div {
  position: relative;
}

div.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.container--white {
  & .ant-card-body,
  & .documents__info-item,
  & .ant-card-head {
    padding: 0;
  }
}

.modal--attributes {
  // margin-top: 12px;
  border: 1px solid #f0f0f0;
  padding: 20px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}
