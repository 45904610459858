.notificationPageModalTestTelephone{
  &__title{
    font-weight: 600;
    font-size: 17px;
    justify-content: center;
    margin-bottom: 10px;
  }
  &__text{
    justify-content: center;
    width:450px;
    &--data{
      margin-left: 5px;
      font-weight: 600;
    }
    &--boldText{
      font-weight: 700 !important;
    }
    &--obliqueText{
      font-style: italic !important;
    }
  }

}

