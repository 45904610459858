.versionCard {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 468px;
  gap: 24px;
  padding: 20px;
  border: 1px solid rgba(240, 240, 240, 1);
}

.restoreButton {
  display: flex;
  align-items: center;
  gap: 8px;
}
