.itemField {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .formItem {
    width: 100%;
  }
  .deleteIcon {
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    right: -16px;
    top: 0px;
    translate: 100%;
    z-index: 10;
  }
}
