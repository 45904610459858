.modal {
  :global {
    .ant-modal-header {
      margin-bottom: 24px;
    }

    .ant-modal-body {
      margin-bottom: 24px;
      & .ant-row {
        display: flex;
        flex-direction: column;
      }

      & .ant-form-item .ant-form-item-label {
        text-align: left;
      }
    }
  }
}

.uploadAttr {
  font-weight: 600;
}
