.scrollBar {
}

.trackY,
.trackX {
  // margin: 2px;
  background: transparent !important;
}

.trackY {
  width: 8px !important;
}

.trackX {
  height: 8px !important;
}

.thumb {
  background: rgba(0, 0, 0, 0.5) !important;
  width: 200px;
  height: 200px;
}
