.verifyAlertContainer {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-end;
  min-width: 130px;
  & :global(.ant-alert) {
    padding: 5px;
    width: 100%;
    text-align: center;
    justify-content: center;
    & :global(.ant-alert-content) {
      flex: 0 1 auto;
    }
  }
}
