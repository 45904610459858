.itemField {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .formItem {
    margin: 5px 0;
    width: calc(100% - 36px);
  }
  .deleteIcon {
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    top: 5px;
    right: 0px;
  }
}
