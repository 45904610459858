.deduplicationProtocol {
  display: flex;
  flex-direction: column;
  &__button-go-back {
    display: flex;
    width: 90px;
    justify-content: flex-start;
  }
  &__filters {
    padding: 1.5rem;
    margin-bottom: 25px;
    background: #fff;
    box-shadow: 0 0 12px 0 rgba(47, 67, 78, 0.19);
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #24292e;
    margin-bottom: 20px;
  }

  &__emptyProtocol {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 40px;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    justify-content: center;
    &--title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  &__spin {
    margin-top: 20px;
    text-align: center;
  }
}
