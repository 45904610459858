.ant-table-row-expand-icon-spaced {
  border: 1px solid #f0f0f0;
}

.ant-table-row-expand-icon-spaced:before,
.ant-table-row-expand-icon-spaced:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  content: '';
  display: block;
}

.recordTable__checkMainItemUuidInData {
  color: #5f5f5f;
  opacity: 0.7;
}

.field-changed {
  td {
    border-top: 1px solid red !important;
    border-bottom: 1px solid red !important;
    &:first-child {
      border-left: 1px solid red !important;
    }
    &:last-child {
      border-right: 1px solid red !important;
    }
  }
}

.ant-table tr.field-changed + tr td {
  border-top: none !important;
}

.highlightRow {
  background-color: #e2eeff;
}

.recordTable_exportSelect {
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.65) !important;
  }
}
