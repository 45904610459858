.deduplicationFilters {
  display: flex;
  flex-direction: row;
  // flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  gap: 8px;

  :global(.ant-form-item) {
    margin-bottom: 0 !important;
  }
}

.filterProtocol {
  display: flex;
  // justify-content: flex-start;
  justify-content: space-between;
  align-items: center;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.name_filter {
  width: 220px;
}

.protocols_filter {
  width: 220px;
}

.author_filter {
  width: 100px;
}

.date_protocol_filter {
  width: 360px;
}

.catalog_filter {
  width: 140px;
}

.status_filter {
  width: 120px;
}
