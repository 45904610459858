.validators {
  margin-bottom: 0;

  :global(.ant-form-item-control-input-content) {
    &:has(.formItem) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2px 24px;
    }
  }

  :global(.ant-form-item) {
    margin-bottom: 0;
  }

  .formItem {
    margin-bottom: 0;
    
    :global(.ant-form-item-control-input-content) {
      display: flex;
      flex-direction: column;
    }
  }

  .validator {
    margin-bottom: 12px;
    
    :global(.ant-form-item-control-input-content) {
      display: flex;
      flex-direction: row;
      margin-top: 8px;
      gap: 12px;

      :global(.ant-input-number) {
        width: 100%;
      }
    }
  }
}
