.verification {
  &Comment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;

    &Input {
      width: 600px;
    }
  }
  &Alert {
    display: flex;
    width: 500px;
    margin-bottom: 10px;
  }

  &Card {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 20px;
    width: 50%;
    border: 1px solid rgba(240, 240, 240, 1);
    :global {
      .ant-typography {
        margin-bottom: 24px;
      }
    }
    &.originVersion {
      width: calc(50% - 80px);
    }
  }
}

.versions {
  display: flex;
  gap: 24px;
  width: calc(100% - 24px);
  max-width: 1440px;
}
