.deduplicationJob {
  display: flex;
  flex-direction: column;
  &__modal {
    display: flex;
    margin: 0 auto !important;
    width: 600px;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 0;
    height: 500px;
  }
  &__spinner-wrapper {
    text-align: center;
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #24292e;
  }
  &__button {
    width: 320px;
    margin: 10px 0 19px 0;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }
  &__button-box {
    display: grid;
    width: 160px;
    cursor: pointer !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    &--text {
      cursor: pointer !important;
      margin-bottom: 14px;
    }
  }
  &__button-icon {
    width: 50px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  &__open-job-id-btn {
    display: flex;
    border: none;
    background: none;
    cursor: pointer !important;
    margin-bottom: 15px;
  }
  &__popover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &--exclamation-point {
      margin-right: 10px;
    }
  }
  &__btn-blue {
    color: #099ca3;
  }
  &__btn-green {
    color: #52c41a;
  }
  &__btn-green-b {
    color: #237804;
  }
  &__btn-red {
    color: #f5222d;
  }
  &__btn-darkBlue {
    color: #336cfb;
  }
  &__btn-yellow {
    color: #ffb648;
  }
  &__button-disabled {
    opacity: 0.3;
    margin-bottom: 15px;
  }
}
