.notificationPageList {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    flex-direction: column;

    &--title {
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: #24292e;
      margin-bottom: 10px;
    }
    &--button {
      width: 220px;
      height: 42px;
      margin-bottom: 20px;
    }
  }
  &--spin {
    text-align: center;
    margin-top: 20px;
  }
}
.toggle {
  width: 70px;
}
