.org-list {
  background: none;

  &__tabs {
    .ant-tabs-bar {
      background-color: white;
      margin-bottom: 0;
    }

    .ant-tabs-nav-wrap {
      padding: 5px;
    }

    .ant-card-head-title {
      color: #1890ff;
    }
  }

  &__no-structure-items {
    background-color: white;
    padding-top: 30px;
  }
}

.field {
  flex-grow: 1;
  max-width: 100%;
  &Item {
    &Bordered {
      box-sizing: border-box;
      margin: 0;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;
      line-height: 1.5714285714285714;
      list-style: none;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      min-height: 32px;
      border-radius: 6px;
      transition: all 0.2s;

      color: rgba(0, 0, 0, 0.5);
      background-color: rgba(0, 0, 0, 0.04);
      border-color: #d9d9d9;
      box-shadow: none;

      opacity: 1;
      border-width: 1px;
      border-style: solid;
      word-wrap: break-word;
      overflow-wrap: break-word;
      hyphens: auto;
      white-space: pre-wrap;
    }

    &_changed {
      border: 1px solid rgba(89, 126, 247, 1);
      &_origin {
        border: 1px solid #f5222d;
      }
    }
  }

  &Item_title {
    margin-bottom: 8px;
  }
}

.renderFields {
  &:has(.containerFields :global(.ant-alert)) {
    .containerFields {
      .renderField {
        width: calc(100% - 142px);
      }
    }
  }
}
.containerFields {
  display: flex;
  gap: 12px;
  .renderField {
    height: 100%;
    align-items: flex-end;

    .verificationChecker {
      display: flex;
      align-items: center;
      height: calc(100% - 29px);
    }
    &:has(:global(.catalogs-item__body)) {
      &:not(:has(:global(.ant-pagination-item-2))) {
        .verificationChecker {
          height: calc(100% - 76px);
        }
      }
    }
  }

  &:has(:global(.catalogs-item__body)) {
    &:global {
      &:has(.ant-pagination-item-2) {
        .ant-pagination {
          margin: 16px 0;
        }

        .ant-alert {
          margin-bottom: 64px;
        }
      }
    }

    &:global {
      &:has(.ant-pagination-options) {
        .ant-pagination {
          margin: 16px 0;
        }
      }
    }

    :global(.ant-pagination) {
      margin: 0;
    }
  }
}

.renderField {
  display: flex;
  // align-items: end;
  width: 100%;
  // max-width: 720px;
  max-width: 100%;
  gap: 8px;
}

.renderItemContainer {
  display: flex;
  max-width: 100%;
  flex: 4 0 0;

  &.verifyDetail {
    max-width: calc(100% - 80px);
  }
}

.two_columns {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  .renderField {
    width: calc(50% - 12px);
  }

  .renderItemContainer {
    max-width: 100%;
  }
}
