@import '../../themes/variables.scss';

.sider {
  position: sticky !important;
  bottom: 0;
  border: 1px solid $colorSplit;
  border-radius: $bigBorderRadius;
  width: ($sidebarWidth) !important;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  :global(.ant-layout-sider-children) {
    height: auto !important;
  }

  .siderMenu {
    overflow-y: auto;
    overflow-x: clip;
    background: #ffffff;
    padding: 0 20px 0 20px;
    scrollbar-width: thin;
    gap: 6px !important;

    &::-webkit-scrollbar {
      width: 0;
    }

    :global(.ant-menu-submenu) {
      div:global(.ant-menu-submenu-title) {
        margin-left: -2px !important;
        width: calc(100% + 4px) !important;
      }
      min-width: 40px;
      width: auto;
      max-width: calc(100% - 8px);
    }

    :global(.ant-menu-submenu .ant-menu-submenu-title) {
      border: 1px solid $colorSplit;
      min-width: 32px;
    }

    &:global(.ant-menu-inline-collapsed) {
      :global(.ant-menu-submenu .ant-menu-submenu-title) {
        :global(.ant-menu-title-content) {
          width: 0;
          height: 0;
          overflow: hidden;
        }
      }

      .userIcon {
        width: 16px;
        height: 16px;
      }
    }

    &.profileMenu {
      padding-top: 20px;
      li:global(.ant-menu-item.ant-menu-item-active:hover) {
        span {
          text-decoration: underline;
        }
      }
    }

    .userIcon {
      width: 32px;
      height: 32px;
      transition: 0.2s;
    }
  }

  .collapsedWrapper {
    margin-top: 20px;
    display: flex;
    margin-left: 3px;
    margin-bottom: 3px;
    padding: 0 20px 0 20px;
  }

  .collapsedButton {
    width: 32px;
    height: 32px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $colorSplit;

    &.collapsed {
      width: 40px !important;
      height: 40px !important;
    }

    :global(.anticon) {
      width: 16px;
      height: 16px;
    }
  }
}

.wrapperSider {
  display: flex;
  box-sizing: border-box;
  position: sticky !important;
  top: 20px;
  margin: 20px;
  height: calc(100vh - 40px);
  background-color: transparent;
  max-width: ($sidebarWidth);
  min-width: ($sidebarWidth);
  width: $sidebarWidth;
  transition: 0.2s;

  &.collapsed {
    width: $sidebarWidthCollapsed;
    min-width: $sidebarWidthCollapsed;
    max-width: $sidebarWidthCollapsed;

    .logo {
      img {
        width: 40px;
      }
    }

    .logo_and_button {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      margin-bottom: 0;
      margin-right: 0;
    }

    :global(.ant-menu-submenu) {
      div:global(.ant-menu-submenu-title) {
        margin-left: 0 !important;
        height: 40px !important;
        width: 40px !important;
        > span:global(.anticon) {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          svg {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }
      min-width: auto !important;
      width: auto;
      max-width: calc(100%);
      margin: 0 !important;
    }

    .collapsedWrapper {
      margin-left: 0;
    }
  }
}

.logo {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 100;
  padding: 20px 0px 0px 20px;

  img {
    width: 160px;
    height: 58px;
    transition: 0.2s;
  }
}

.logo_and_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 6px;
}

@media (max-width: 1024px) {
  .sider {
    position: fixed;
    z-index: 100;
  }

  .wrapperSider {
    width: 80px;
  }
}
