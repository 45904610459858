.upperBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.form {
  max-width: 720px;
  :global(.ant-form-item-row) {
    margin-bottom: 14px;
  }
  &.noEdit {
    :global(.ant-form-item-label) {
      padding: 0;
      label {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    :global {
      .anticon-check,
      .anticon-close {
        svg {
          font-size: 14px;
        }
      }
    }
  }
}
