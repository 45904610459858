.modalInfo {
}

.modal {
  :global(.ant-modal-body) {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;
    min-height: auto;
    max-height: calc(100vh - 224px);
    padding-right: 10px;
  }
}

:global {
  .ant-modal-content {
    &.ant-btn-primary {
      background-color: #597ef7;

      &:disabled {
        background-color: rgba(0, 0, 0, 0.04);
      }

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: #fff;
        background-color: #85a5ff;
      }
      &:not(:disabled):not(.ant-btn-disabled):active {
        background-color: #415ed1;
      }
    }
  }
}
