$color-green: rgba(82, 196, 26, 1);
$color-green-b: rgba(35, 120, 4, 1);
$color-yellow: rgba(250, 173, 20, 1);
$color-red: rgba(245, 34, 45, 1);

$color-primary: rgba(89, 126, 247, 1);
$primary-color: rgba(89, 126, 247, 1);
$border-color: rgba(240, 240, 240, 1);
$color-success: $color-green;
$color-warning: $color-yellow;
$color-error: $color-red;
$color-icon: rgba(0, 0, 0, 0.45);
