.container {
  border-radius: 16px;
  background: #fff;
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.recordContainer {
  padding: 20px;
  border: 1px solid #f0f0f0;
  display: flex;
}

.records {
  width: 100%;
}

.footerButtons,
.underRecordsButtons {
  display: flex;
  gap: 12px;
}

.formInitiator {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
