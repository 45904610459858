.recordForm {
  width: 100%;
  // &.card {
  //   border: 1px solid #f0f0f0 !important;
  //   padding: 20px;
  // }
  // max-width: 720px;
}

:global(.ant-modal-body) {
  .recordForm {
    max-width: 100%;
  }
}

.modalVersion {
  :global(.ant-modal-body) {
    padding-right: 0;
  }
}
