
.contacts-page {
  color: #5F5F5F;
  font-size: 18px;


  a {
    text-decoration: underline;
    color: #27AAE1;
  }
}
