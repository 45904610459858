.catalogsFilters {
  display: flex;
  flex-direction: column;
  // gap: 24px;
}

.collapse {
  border: none;
  &Panel {
    margin: 5px;
    &Input {
      width: 600px;
    }
  }
}
.clue {
  margin-top: 5px;
  opacity: 0.7;
}
.button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.filter-item {
  padding: 12px 12px;
  //background: #fbfbfb;
  border: 1px solid rgba(240, 240, 240, 1);
  display: flex;
  //width: 400px;
  //justify-content: space-between;
  //   margin: 5px;
}

.filter-subitem {
  //   padding: 12px 12px;
  //background: #fbfbfb;
  display: flex;
  //width: 400px;
  //justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  margin: 5px;
  margin-left: 20px;
}

.operator-block {
  //   width: 204px;
  position: relative;
  //   width: auto;
  &::after {
    border-right: 1px solid rgba(240, 240, 240, 1);
    content: '';
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
  }
}
