@import './themes/variables.scss';

.app {
  & > div {
    height: 100%;
  }

  &-title {
    color: red;
    font-size: 1.5em;
  }

  &__main {
    overflow: hidden;
    height: 100%;
    transition: all 0.5s ease-out;
    margin-left: 250px;
    padding-left: unset;

    & > div {
      overflow: auto;
      height: 100%;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
      &::-webkit-scrollbar-button {
        background-color: #ced4da;
      }
      &::-webkit-scrollbar-track {
        background-color: #ced4da;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: #ffffff;
      }
      &::-webkit-scrollbar-thumb {
        height: 50px;
        background-color: #ced4da;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-corner {
        background-color: #999;
      }
      &::-webkit-resizer {
        background-color: #666;
      }
    }
  }
}

.appContent {
  padding: 20px 24px 20px 0;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  gap: 32px;
}

.mainContainer {
}

.container {
}

.containerWhite {
  z-index: 10;
  padding: 40px 24px 40px 24px;
  margin-bottom: 25px;
  background: #fff;
  border-radius: $bigBorderRadius;
  border: 1px solid $colorSplit;
  // box-shadow: 0 0 12px 0 rgba(47,67,78,.19);

  & .ant-card-body,
  & .documents__info-item,
  & .ant-card-head {
    padding: 0 !important;
  }
}


.ant-modal-body {
  .documents__info-item {
    .ant-form-item {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}

.exclamationIcon {
  cursor: pointer;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.45);
}

.default-column-gap {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.default-gap,
.gap-24 {
  gap: 24px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px 24px;

  &--mobile {
    grid-gap: 0px 24px;
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px 24px;

  &--mobile {
    grid-gap: 0px 24px;
  }
}

// переопределение стилей AntD

// кнопки
.ant-btn {
  display: inline-flex;
  align-items: center;
  &.ant-btn-icon-only {
    justify-content: center;
  }
}

.table {
  &--link {
    color: rgba(0, 0, 0, 0.88) !important;
  }
}

.icon {
  &--size-32 {
    font-size: 32px;
  }

  &--grey-color {
    color: var(--icon--grey-color);
  }

  &--primary-color {
    color: var(--icon--primary-color);
  }

  &--error-color {
    color: var(--icon--error-color);
  }

  &--group {
    gap: 4px;
  }
}

:root {
  --icon--primary-color: rgba(89, 126, 247, 1);
  --icon--grey-color: rgba(0, 0, 0, 0.45);
  --icon--error-color: rgba(245, 34, 45, 1);
}

// Переопределение стандартного скролла
/* Для всего документа */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Обработка трека (дорожки), по которой движется бегунок */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 6px;
}

/* Обработка самого бегунка */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  transition: 0.3s;
  cursor: pointer;
}
