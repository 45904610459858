.helpModal {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  gap: 12px;
  .helpModalUrl {
    width: min-content;
  }
  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    &__icon {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
