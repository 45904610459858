// colors

$colorPrimary: #597EF7;
$colorError: #F5222D;
$colorSplit: #f0f0f0;

$colorText: rgba(0, 0, 0, 0.88);
$colorTextSecondary: rgba(0, 0, 0, 0.65);

// base variables
$bigBorderRadius: 16px;

// sidebar
$sidebarWidth: 360px;
$sidebarWidthCollapsed: 80px;