.PhoneInput {
  & .PhoneInputInput {
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    border: 1px solid #d9d9d9;
    // border-radius: 2px;
    border-radius: 6px;
    &:focus {
      outline: none;
      border-color: #597ef7;
    }
  }

  .PhoneInputCountryIcon {
    svg {
      margin-top: -10px !important;
    }
    .anticon-phone svg {
      margin-top: 0 !important;
    }
  }
}
