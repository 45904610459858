.renderFields {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &.card {
    border: 1px solid #f0f0f0;
    padding: 20px;
  }

  &:has(.formItemWrapper :global(.ant-alert.ant-alert-success), .formItemWrapper :global(.ant-alert.ant-alert-error)) {
    .formItemWrapper {
      display: flex;
      .formItem {
        width: calc(100% - 142px);
      }

      &.valuesArrayField {
        :global(.ant-alert) {
          margin-bottom: 61px;
        }
      }
    }
  }
}

.formItemWrapper {
  // display: flex;
  // align-items: end;
  gap: 12px;
  width: 100%;

  &:has(:global(.catalogs-item__body)) {
    &:global {
      &:has(.ant-pagination-item-2) {
        .ant-pagination {
          margin: 16px 0;
        }

        .ant-alert {
          margin-bottom: 64px;
        }
      }
    }

    &:global {
      &:has(.ant-pagination-options) {
        .ant-pagination {
          margin: 16px 0;
        }
      }
    }

    :global(.ant-pagination) {
      margin: 0;
    }
  }
}

.formItem {
  // max-width: 720px;
  width: 100%;
  // flex: 4 1 0;
  margin: 0 !important;
}

:global(.ant-modal-body) {
  .formItem {
    max-width: 100%;
    flex: unset;
  }
}
