$color: #27AAE1;
$glowSize: 10px;

.noaccess {
  box-sizing: border-box;
  padding: 1rem;
  background: none;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: $color;
  text-shadow: 0 0 $glowSize;
  font-size: 6rem;
  flex-direction: column;

  &__text {
    font-size: 1.8rem;
  }
}
@keyframes blink {
  0%   {opacity: 0}
  49%  {opacity: 0}
  50%  {opacity: 1}
  100% {opacity: 1}
}

.blink {
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
