.hidePagination {
  :global(.ant-pagination-item) {
    display: none !important;
  }

  :global(.ant-pagination-prev) {
    display: none !important;
  }

  :global(.ant-pagination-next) {
    display: none !important;
  }
}

.table {
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;
  // border-radius: 0 !important;

  // :global(.ant-table),
  // :global(.ant-table-container),
  // :global(.ant-table-content),
  // table {
  //   border-top-left-radius: 0 !important;
  //   border-top-right-radius: 0 !important;
  //   border-radius: 0 !important;
  // }

  &:global(.layout-fixed) {
    table {
      table-layout: fixed !important;
    }
  }
  :global(.ant-table.ant-table-bordered > .ant-table-container) {
    border-top: none;
    border-inline-start: none;
    th,
    td {
      padding: 8px 12px;
      height: 40px;
    }
    :global(.ant-table-content > table > thead > tr) {
      th,
      td {
        border-inline-end: none;
        background: rgba(0, 0, 0, 0.02);
      }
      th::before {
        background-color: #f0f0f0 !important;
      }
      &:first-child > *:last-child,
      &:first-child > *:first-child {
        border-radius: unset;
      }
    }

    :global(tbody > tr > td) {
      &:has(:global(.anticon)) {
        width: 40px;
      }
      :global(.anticon) {
        // color: #00000073;
        svg {
          font-size: 16px;
          color: #8c8c8c;
        }
      }
      &:first-child {
        border-inline-start: 1px solid #f0f0f0;
      }
    }
  }

  :global(.ant-table-thead) > tr > th {
    opacity: 1;
    font-weight: 500;
  }
  :global(.ant-table-row-expand-icon) {
    border-radius: 0;
    width: 13px;
    height: 13px;
    border-color: rgba(0, 0, 0, 0.45);

    &:global(.ant-table-row-expand-icon-collapsed) {
      &::after {
        top: 2px;
        left: 5px;
        height: 7px;
        width: 1px;
      }
      &::before {
        top: 5px;
        left: 2px;
        width: 7px;
        height: 1px;
      }
    }

    &:global(.ant-table-row-expand-icon-expanded) {
      &::before {
        top: 5px;
        left: 2px;
        width: 7px;
        height: 1px;
      }

      &::after {
        top: 2px;
        left: 5px;
        height: 7px;
        width: 1px;
      }
    }
  }
}
