.detailModal {
  .detailTable {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px 0;

    .beforeTableButtons {
      display: flex;
      justify-content: space-between;
    }
  }

  .afterTableButtons {
    display: flex;
    justify-content: end;
    gap: 12px;
  }
}

.formItems {
  display: flex;
  gap: 24px;
  padding: 24px 0;

  .formItem {
    flex: 1 1 0;
  }
}

.footerButtons {
  display: flex;
  justify-content: end;
  gap: 12px;
}
