.deduplicationFilters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #ffffff;

  &--filterProtocol {
    display: flex;
    justify-content: flex-start;
  }

  &__name-filter {
    width: 200px;
  }

  &__protocols {
    width: 230px;
  }

  &__catalogs {
    width: 280px;
  }
  &__status-connection {
    width: 250px;

    &--filterProtocol {
      width: 300px;
      margin-right: 24px;
    }
  }
  &__user-name {
    width: 300px;
    // margin-right: 240px;

    &--filterProtocol {
      width: 350px;
    }
  }
  &__button {
    display: flex;
    flex-direction: row;
    width: 210px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  &__date-protocol-filter {
    width: 300px;
  }
}
