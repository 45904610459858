@import '../../../styles/colors.scss';

.popover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .exclamation_point {
    margin-right: 8px;
  }

  &__content {
    padding: 0;
    margin: 0;
    padding-left: 16px;
  }
}

.rootPopover {
  width: 560px;
  :global(.ant-popover-inner) {
    width: 100%;
    max-width: 100%;
  }
}

.deleteIcon {
  font-size: 17px;
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
  }
}

.startIcon {
  font-size: 17px;
  color: $primary-color;
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
  }
}

.status {
  &__new {
    color: $color-green-b;
  }

  &__success {
    color: $color-success;
  }

  &__process {
    color: $primary-color;
  }

  &__error {
    color: $color-error;
  }
  &__order {
    color: $color-warning;
  }
}

.modal {
  display: flex;
  margin: 0 auto !important;
  // width: 760px;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 0;
  height: 500px;
  :global(.ant-modal-content) {
    width: 100%;
  }
}

.open_job_id_btn {
  display: flex;
  border: none;
  background: none;
  cursor: pointer !important;
  margin-bottom: 15px;
}

.button_box {
  display: grid;
  width: 160px;
  cursor: pointer !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  &__text {
    cursor: pointer !important;
    margin-bottom: 14px;
  }
}

.statusIcon {
  color: rgba(0, 0, 0, 0.45);
  svg {
    width: 18px;
    height: 18px;
  }
}
