.attributesUnique{
  word-wrap: break-word;
  width: 600px;
}

.attributes {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}