.documents__info-title {
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.847059);
}

.documents__info-status {
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  color: rgba(102, 102, 102, 0.847059);
}

.documents__info-label {
  font-weight: bold;
}
