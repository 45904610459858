.columnsSelect {
  :global(.ant-select-selection-item) {
    border-color: transparent !important;
  }
}

.table {
  :global(.ant-table-row.ant-table-row-selected) > :global(.ant-table-cell) {
    background-color: transparent !important;
  }
}
