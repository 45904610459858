@import './app.scss';

@font-face {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;

  src: url('./assets/fonts/Roboto-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;

  src: url('./assets/fonts/Roboto-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;

  src: url('./assets/fonts/Roboto-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  src: url('./assets/fonts/Roboto-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;

  src: url('./assets/fonts/Inter-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Inter-Medium';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;

  src: url('./assets/fonts/Inter-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Inter-Bold';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;

  src: url('./assets/fonts/Inter-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Light';
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  src: url('./assets/fonts/Inter-Light.ttf') format('ttf');
}

html,
body {
  height: 100%;
  background: #f5f5f5;
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

#react-container {
  height: 100%;
}
.text-wrap {
  white-space: normal !important;
  overflow-wrap: normal !important; /* не поддерживает IE, Firefox; является копией word-wrap */
  word-wrap: normal !important;
  word-break: break-word !important; /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */
  line-break: auto !important; /* нет поддержки для русского языка */
  hyphens: manual !important; /* значение auto не поддерживается Chrome */
}

.container--white {
  z-index: 10;
  padding: 1.5rem;
  margin-bottom: 25px;
  background: #fff;
  box-shadow: 0 0 12px 0 rgba(47, 67, 78, 0.19);
}
.notificationHiddenAttributes {
  padding: 15px 0 15px 0;
  font-weight: 500;
  color: gray;
}

.menu-tab-box {
  display: flex;
  background-color: #326fb9;
  height: 80px;
  padding: 10px 0;
  overflow: hidden;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.menu-tab-box {
  width: 100%;
  position: fixed;
  z-index: 100;
}

.anticon {
  vertical-align: 0 !important;
}

.link {
  color: #597ef7;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}

.column--text {
  white-space: pre-wrap;
}

.fixed-button {
  display: flex;
  justify-content: flex-end;
  z-index: 999;
}
.disabledRow {
  opacity: 0.4;
}
.colorExclamationPoint {
  color: #5f5f5f;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ant-table-thead > tr > th {
  position: relative;
  cursor: auto;
  color: rgba(36, 41, 46, 0.5);
  font-weight: 500;
  text-align: left;
  font-style: normal;
  font-size: 14px;
  opacity: 0.5;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}

.ant-menu-title-content {
  text-overflow: clip !important;
  white-space: normal !important;
  line-height: 16px;
}

.ant-menu-submenu-title {
  height: 45px;
}

// пока тут, потом уберем в другое место

.transition {
  transition: 0.2s;
}

// скрываем фрейм keycloak - использовать только для разработки

iframe[style*='z-index: 2147483647'] {
  width: 0 !important;
}
