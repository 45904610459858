

.ant-descriptions-bordered {
  & table {
    border-collapse: collapse;
  }
}

.ant-descriptions-item-content {
  background-color: white;
}
