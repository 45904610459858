@import '../../themes/variables.scss';


.pageHeader {
  padding: 20px 24px 20px 24px;
  background-color: #fff;
  border-radius: $bigBorderRadius;
  border: 1px solid $colorSplit;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .breadcrumb {
    display: flex;
    align-items: center;
    gap: .5rem;
    :global(.anticon) {
      font-size: 1.1rem !important;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    h2 {
      line-height: 36px;
      margin-bottom: 0.33rem;
    }
  }

  .buttonsBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem
  }

  .top {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .backButton {
    &.hide {
      width: 0 !important;
      opacity: 0 !important;
      visibility: hidden;
      position: absolute;
      overflow: hidden;
    }
  }

  .tag {
    margin-left: 16px;
  }  
}

.flexGrow {
  flex-grow: 1;
}