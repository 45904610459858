.notificationFilters {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 17px 0 7px;
  background: #ffffff;
  gap: 24px;
  &__name-filter {
    width: 200px;
  }
  &__type-filter {
    width: 200px;
  }
  &__subscription-filter {
    width: 300px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 8px;
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  &__date-protocol-filter {
    width: 300px;
  }
}
