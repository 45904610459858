.deduplicationProtocolForm {
  &__spinner-wrapper {
    text-align: center;
  }
  &__title {
    font-weight: 600;
    font-size: 18px;
    color: #101828;
    margin-top: 5px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__popoverResemblance {
    display: flex;
    width: 200px;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  &__form-list {
    &__head-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 13px;
      margin-top: 24px;
      &--item-one {
        width: 180px;
        margin-right: 15px;
      }
      &--item-two {
        display: flex;
        flex-direction: row;
        width: 220px;
        margin-right: 15px;
      }
    }
  }
  &__button {
    margin-top: 25px;
    gap: 8px;
  }
}
