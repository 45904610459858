.modalMode-table {
  :global(.ant-table-content),
  :global(.ant-table-container) {
    border-radius: 0 !important;
  }

  table {
    border-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
  :global(.ant-table-cell) {
    padding: 0px 8px 0px 8px !important;
    height: 36px !important;
    color: rgba(0, 0, 0, 0.88) !important;
  }

  :global(.ant-table-thead) * {
    background: rgba(0, 0, 0, 0.02) !important;
  }
}

.warningIcon {
  color: #faad14;
  font-size: 20px;
}

.popover {
  max-width: 220px;
}
