.panel {
  display: flex;
  gap: 24px;
}

.version {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 468px;
  gap: 8px;
}
