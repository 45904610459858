.formBlock {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-bottom: 24px;
}

.formItem {
  flex: 0 1 calc(50% - 12px) !important;
  margin-bottom: 0;
}

.form {
  margin: 0 auto;
}
