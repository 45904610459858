.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2px 24px;
  &.optionsAttribute {
    :global(.ant-form-item) {
      margin-bottom: 0;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
