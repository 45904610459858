@import '../../../styles/colors.scss';

.deduplicationProtocols {
  display: flex;
  flex-direction: column;
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #24292e;
    margin-bottom: 20px;
  }

  &__spinner-wrapper {
    text-align: center;
  }
}

.open_protocol_btn {
  margin-bottom: 14px;
  color: $primary-color;
}

.zip {
  color: #5f5f5f;
  opacity: 0.7;
}
