.verificationChecker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.iconGroup {
  display: flex;
  gap: 4px;
}

.iconGrey {
  display: flex;
  font-size: 32px;
  color: var(--icon--grey-color);
  transition: color 0.3s;
}

.iconPrimary {
  color: var(--icon--primary-color);
}

.iconError {
  color: var(--icon--error-color);
}
