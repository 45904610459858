.collapse {
  border-radius: 0 !important;
  border: 0 !important;

  :global(.ant-collapse-content),
  :global(.ant-collapse-header),
  :global(.ant-collapse-item) {
    border-radius: 0 !important;
    border-color: rgba(240, 240, 240, 1) !important;
  }
}
