.catalogsFilters {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__collapse {
    border: none;
    &--panel {
      &--input {
        width: 600px;
      }
    }
  }
  &__clue {
    margin-top: 5px;
    word-wrap: break-word;
    width: 600px;
    opacity: 0.7;
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-top: 5px;
    &--end {
      margin-left: 10px;
    }
  }
}
