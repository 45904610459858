.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonIcon {
  color: #00000073;
}

.modalEditAttr {
  & :global(.ant-modal-body) {
    padding-right: 0;
  }
  & :global(.container) {
    padding-left: 0;
    padding-right: 0;
  }
}
