.popover {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: #101828;
  margin-top: 5px;
}

.head_menu {
  display: flex;
  flex-direction: row;
  &__item_one {
    width: 50%;
    margin-right: 30px;
  }
  &__item_two {
    width: 50%;
  }
  &__item_three {
    width: 100%;
  }
}

.form_list {
  &__head_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 13px;
    margin-top: 24px;
    gap: 16px;
    > div,
    h5 {
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
    &__item_one {
      width: 180px;
    }
    &__item_two {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }
    &__item_three {
      width: 130px;
      display: flex;
      flex-direction: row;
    }
  }
}

.button {
  margin-top: 25px;
  gap: 8px;
}
