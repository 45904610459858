.deduplicationGroupModal {
  display: flex;
  flex-direction: column;
  max-width: 1100px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__button_box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 0 0 0;
    gap: 12px;

    &_text_red {
      text-align: center;
      color: #ff4d4f;
    }
    &__spin {
      margin-top: 20px;
      text-align: center;
    }
  }
}

.deleteModal {
  &_content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
